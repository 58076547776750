:root {
    --primary: #7c1919;
    --light: #FFF;
    --dark: #0F172B;
	--active: #87000048;
}

* {
	/* font-family: Arial, Helvetica, sans-serif; */
	box-sizing: border-box;
	
  }

  body {
	padding: 0; margin: 0;
  }

  .app-container {
	display: flex;
	min-height: 100vh;
	padding: 0; margin: 0;
  }
  
  /* (B) SIDEBAR */
  /* (B1) SIDEBAR ITSELF */
  #pgside {
	width: 200px;
	flex-shrink: 0;
	transition: width 0.2s;
	background: #283039;
  }
  
  /* (B2) USER OR BRANDING */
  #pgside #pguser {
	display: flex;
	align-items: center;
	padding: 10px 5px;
  }
  #pgside #pguser img {
	width: 50px;
	margin-right: 5px;
	border-radius: 50%
  }
  
  /* (B3) SIDEBAR ITEMS */
  #pgside, #pgside a { color: #fff; }
  #pgside a {
	display: block;
	padding: 20px;
	width: 100%;
	text-decoration: none;
	cursor: pointer;
  }
  #pgside a.current { background: var(--primary); }
  #pgside a:hover { background: #9b2323; }
  
  /* (B4) SIDEBAR ICONS & TEXT */
  #pgside i.ico, #pgside i.txt { font-style: normal; }
  #pgside i.ico {
	font-size: 1.1em;
	margin-right: 10px;
  }
  
  /* (B5) SMALL SCREEN TRANSFORMATION */
  @media screen and (max-width:768px) {
	#pgside { width: 70px; }
	#pgside #pguser { justify-content: center; }
	#pgside a {
	  text-align: center;
	  padding: 20px 0;
	}
	#pgside i.ico {
	  font-size: 1.5em;
	  margin-right: 0;
	}
	#pgside i.txt { display: none; }
  }
  
  /* (C) MAIN CONTENTS */
  #pgmain {
	flex-grow: 1;
	padding: 20px;
	background: #f2f2f2;
  }

/* (A) FORM */
.form {
	max-width: 600px; /* optional */
	padding: 20px;
	border: 1px solid #eee;
	background: #fafafa;
}
  
/* Tabs */
.navigation {
	display: flex;
    flex-wrap: wrap;
    padding-left: 0;
    margin-bottom: -1px;
	border-bottom: 1px solid var(--light);
}

.tab {
    display: block;
    padding: .5rem 1rem;
    border: none;
    cursor: pointer;
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
	text-transform: uppercase;
	color: var(--dark);
}

.tab.active {
	border: none;
	color: var(--primary);
	box-shadow: unset;
	background: var(--light);
}

.tab.disabled {
	cursor: not-allowed;
	opacity: .55;
}

.tab-content {
	padding: 20px;
    box-shadow: 0 0 0 1px var(--light);
	background-color: var(--light);
	border-radius: 1px;
}

/* Tabs */

/* Input */

.input{
	height: 40px;
	width: 100%;
	padding: 0 15px;
	margin-bottom: 0.7rem;
	border: 1px solid #ddd;
	border-radius: 6px;
	outline: none;
	min-width: 250px;
}

.input:disabled {
	background-color: #eee;
}

.big-input {
	height: 60px;
	font-size: 17px;
}

.input:focus{
	box-shadow: 0 1px 0 rgba(0,0,0,0.2);
}

.label-input {
	margin-bottom: 5px;
}

/* Input */

/* Select */

.custom-select{
	height: 40px;
	width: 100%;
	margin-bottom: 0.7rem;
	border: 1px solid #ddd !important;
	border-radius: 6px;
	outline: none;
	box-shadow: none !important;
	cursor: text;
	min-width: 250px;
}

.custom-select:hover{
	border: 1px solid #ddd !important;
}

.custom-select:focus{
	box-shadow: 0 1px 0 rgba(0,0,0,0.2) !important;
	border: 1px solid #ddd !important;
}

.custom-select.disabled{
	cursor: default !important;
    background-color: #eee;
}

/* Select */

/* Button */

.button {
	padding: 10px;
	border-radius: 6px;
	border: 1px solid var(--light);
	color: var(--light);
	background: var(--primary);
	cursor: pointer;
	transition: 0.4s;
	min-width: 5rem;
	margin: 0 10px;
}

.button.rounded {
	padding: 0px;
	min-width: 10px;
	width: 2rem;
	height: 2rem;
	border-radius: 20px;
	margin: auto;
	margin-left: 10px;
	margin-right: 10px;
	text-align: center;
}

.button.block-button{
	height: 60px;
	width: 100%;
	padding: 0 15px;
	outline: none;
	font-size: 1.2rem;
	font-weight: 500;
	letter-spacing: 1px;
	margin: 0;
}

.button.outline{
	border: 1px solid var(--primary);
	color: var(--primary);
	background: var(--light);
}

.button:hover:enabled{
	background: #9b2323;
}

.button.outline:hover:enabled{
	color: var(--light) 
}

.button:disabled {
	opacity: 0.6;
	cursor: default;
}
/* Button */

/* Spinner */
.preloader {
	position: fixed;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	overflow: auto;
	background-color: #00000055;
	z-index: 999999999;
  }

.loading-spinner {
	display: block;
	position: fixed;
	z-index: 1031;
	top: 50%;
	right: 50%;
}

.lds-ring {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
  }
.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 64px;
	height: 64px;
	margin: 8px;
	border: 8px solid var(--primary);;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: var(--primary) transparent transparent transparent;
}
.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}
.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}
.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}
@keyframes lds-ring {
	0% {
	  transform: rotate(0deg);
	}
	100% {
	  transform: rotate(360deg);
	}
}
/* Spinner */

/* Login */

.login-container{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%,-50%);
	max-width: 430px;
	width: 100%;
	background: #fafafa;
	border-radius: 7px;
	box-shadow: 0 5px 10px rgba(0,0,0,0.3);
}

.login-container .form{
	padding: 2rem;
}

.login-container header{
	font-size: 2rem;
	font-weight: 500;
	text-align: center;
	margin: 1.5rem;	
}

/* Login */

/* Table */

.table-container {
	padding: 1rem;
}

.tabulator-selected, .tabulator-row:hover {
	background-color: var(--active) !important;
}

/* Table */

/* Dialog */

.dialog-container {
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	overflow-y: auto;
}

.dialog-container.show {
	display: block;
}

.dialog-container.static {
	animation: beat 0.3s ease-in-out;
}

.dialog {
    position: relative;
    z-index: 1;
	max-width: 500px;
	border-radius: 5px;
	margin: 32px auto;
	background-color: var(--light);
	color: var(--primary);
	animation: fadeIn 0.3s, slideIn 0.4s linear;
}

.dialog-header {
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	padding: 16px;
	background: var(--light);
	border-radius: 5px 5px 0 0;
	border-bottom: 1px solid var(--primary);
}

.dialog-header .title h2 {
	margin-bottom: 0;
}

.dialog-header .close {
	align-self: center;
	opacity: 0.6;
	cursor: pointer;
}

.dialog-header .close:hover {
	color: var(--active);
}

.dialog-content {
	background: var(--light);
	padding: 16px;
	border-radius: 5px;
}

@media (min-width: 576px) {
	.dialog {
		max-width: 500px !important;
	}
}

@media (min-width: 992px) {
	.dialog-xsmall {
		max-width: 350px !important;
	}
	.dialog-small {
		max-width: 420px !important;
	}
	.dialog-medium {
		max-width: 520px !important;
	}
	.dialog-large {
		max-width: 650px !important;
	}
	.dialog-xlarge {
		max-width: 750px !important;
	}
	.dialog-xxlarge {
		max-width: 900px !important;
	}
	.dialog-xxxlarge {
		max-width: 1200px !important;
	}
}


@keyframes slideIn {
	0% {
	  	transform: translateY(-50px);
	  	animation-timing-function: ease-out;
	}
	100% {
	  	transform: translateY(0px);
	  	animation-timing-function: ease-in;
	}
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes beat {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.01);
    }
    100% {
        transform: scale(1);
    }
}

.simple-dialog-content {
	padding: 24px 16px;
	border-bottom: 1px solid var(--primary);
	margin: -16px -16px 0 -16px;
}

/* Dialog */

/* Cover */

.cover-container {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1;
	background-color: #00000055;
}

.cover-transparent {
	background: unset;
}

/* Cover */

/* Common */

.flex-end-container {
	padding-top: 16px;
	display: flex;
	justify-content: flex-end;
}

.flex-start-container {
	padding-top: 16px;
	display: flex;
	justify-content: flex-start;
}

.flex-between-container {
	padding-top: 16px;
	display: flex;
	justify-content: space-between;
}

.container {
	max-width: 650px;
	border: 1px solid var(--primary);
	border-radius: 5px;
	padding: 20px;
}

.m5 {
	margin-left: 5px;
	margin-right: 5px;
}

/* Common */

.text-editor {
	color: #000;
}


/* Draftjs override */

.public-DraftStyleDefault-block {
	margin-top: 1px;
}

#textEditorId figure {
	margin-bottom: 1px;
}

.text-editor-fixed-toolbar{
	position: fixed;
	top: 0px;
	z-index: 999;
}

/* Draftjs override */

/* Text Label */
.label-text-container {
	margin-bottom: 5px;
	align-self: top;
}

.label-text-container .label-text {
	width: 200px;
	display: inline-block;
	
}
.label-text-container .label-text-content {
	max-width: 500px;
	display:inline-flex;
}

/* Text Label */

.img-container {
	display: flex;
	align-items: center;
	margin: 5px
}

.img-container img {
	width: 200px;
	height: auto;
}